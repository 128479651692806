import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Button from "#components/Button";
import Heading from "#components/Heading";
import Text from "#components/Text";

import styles from "./PromoCard.module.css";

PromoCard.propTypes = {
  image: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  content: PropTypes.node,
  buttonText: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

PromoCard.defaultProps = {
  className: undefined,
  content: undefined,
  href: undefined,
};

function PromoCard({
  className,
  image,
  title,
  content,
  href,
  buttonText,
  ...props
}) {
  const hasButton = Boolean(href) && Boolean(buttonText);

  return (
    <div className={classnames(styles.root, className)} {...props}>
      <div className={styles.image}>{image}</div>
      {/*
        <Text variant="monospace16" weight="bold" className={styles.subtitle}>
          {subtitle}
        </Text>
      */}
      <Heading variant="title4" weight="semibold">
        {title}
      </Heading>
      {content && (
        <Text variant="paragraph16" className={styles.content}>
          {content}
        </Text>
      )}
      {hasButton && typeof href === "string" ? (
        <Button color="tertiary" href={href} className={styles.button}>
          {buttonText}
        </Button>
      ) : (
        React.cloneElement(href, {
          render: function render(state) {
            if (state.type === "success") {
              return (
                <Button
                  color="tertiary"
                  href={state?.content?.props?.children}
                  className={styles.button}
                >
                  {buttonText}
                </Button>
              );
            }
            return null;
          },
        })
      )}
    </div>
  );
}

export default PromoCard;
