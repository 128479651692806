import { md, Node } from "djedi-react";
import React from "react";

import Heading from "#components/Heading";
import Tabs from "#components/Tabs";
import Text from "#components/Text";
import FoodPictogram from "#icons/pictograms/food.svg";
import HotBeveragePictogram from "#icons/pictograms/hot-beverage.svg";
import MyVrPictogram from "#icons/pictograms/mittvr.svg";
import RefundPictogram from "#icons/pictograms/refund.svg";
import RestaurantPictogram from "#icons/pictograms/restaurant.svg";
import SocialPictogram from "#icons/pictograms/social.svg";
import SuitePictogram from "#icons/pictograms/suite.svg";
import SustainabilityPictogram from "#icons/pictograms/sustainability.svg";
import WifiPictogram from "#icons/pictograms/wifi.svg";

import styles from "./TabbedInfo.module.css";

const CustomHeading = (props) => (
  <Heading variant="title3" color="textPrimary" gutter="md" {...props} />
);
const CustomText = (props) => (
  <Text variant="paragraph16" color="primary" {...props} />
);

// Needs to be global scope for some reason, guesing djedi-react babel plugin doesn't work in functions....
const BT_LINK_FIRST = <Node uri="home/info/beforeTrip/first-link" />;
const BT_LINK_SECOND = <Node uri="home/info/beforeTrip/second-link" />;
const BT_LINK_THIRD = <Node uri="home/info/beforeTrip/third-link" />;

const TAB_1 = {
  title: <Node uri="home/info/beforeTrip/title">Innan du reser</Node>,
  content: (() => {
    const PICTOGRAM_FIRST = <FoodPictogram className={styles.svg} />;
    const PICTOGRAM_SECOND = <SuitePictogram className={styles.svg} />;
    const PICTOGRAM_THIRD = <RefundPictogram className={styles.svg} />;

    return (
      <div className={styles.root}>
        <CustomHeading>
          <Node uri="home/info/beforeTrip/innerTitle.md">{md`
Innan du reser
          `}</Node>
        </CustomHeading>
        <CustomText>
          <Node uri="home/info/beforeTrip/content.md">{md`
            Boka sommarens resor fram till 1 september, nu även till Varberg, Falkenberg och Halmstad.
          `}</Node>
        </CustomText>
        <div className={styles.pictograms}>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(BT_LINK_FIRST, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>{PICTOGRAM_FIRST}</a>
                  );
                }
                return PICTOGRAM_FIRST;
              },
            })}
            <CustomText>
              <Node uri="home/info/beforeTrip/first.md">
                Brukar du också vara lite stressad på morgonen och glömma
                frukosten hemma? Eller vill du välja din lunch i lugn och ro? Då
                kan du förbeställa och betala din mat redan när du bokar din
                resa. Maten hämtar du ut i cafét väl på plats.
              </Node>
            </CustomText>
          </div>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(BT_LINK_SECOND, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>
                      {PICTOGRAM_SECOND}
                    </a>
                  );
                }
                return PICTOGRAM_SECOND;
              },
            })}
            <CustomText>
              <Node uri="home/info/beforeTrip/second.md">
                Är ni fler som vill ut och resa? Om ni är upp till åtta personer
                så kan ni boka en av våra suiter där ni sitter enskilt- perfekt
                för kompisgänget eller för jobbmötet! Om ni är fler än 20
                personer kan ni kontakta mtrx.grupp@mtr.se för att få hjälp med
                er gruppbokning.
              </Node>
            </CustomText>
          </div>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(BT_LINK_THIRD, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>{PICTOGRAM_THIRD}</a>
                  );
                }
                return PICTOGRAM_THIRD;
              },
            })}
            <CustomText>
              <Node uri="home/info/beforeTrip/third.md">
                Se till att logga in på ditt ClubX- konto innan du bokar din
                resa. Vem vet, du kanske har tillräckligt för att boka en resa
                med poäng? Förutom det kan du även lägga till favoritresenärer
                och förbeställa mat. Allt för att förenkla ditt resande!
              </Node>
            </CustomText>
          </div>
        </div>
      </div>
    );
  })(),
};

// Needs to be global scope for some reason, guesing djedi-react babel plugin doesn't work in functions....
const OT_LINK_FIRST = <Node uri="home/info/onTrip/first-link" />;
const OT_LINK_SECOND = <Node uri="home/info/onTrip/second-link" />;
const OT_LINK_THIRD = <Node uri="home/info/onTrip/third-link" />;

const TAB_2 = {
  title: <Node uri="home/info/onTrip/title">Under resan</Node>,
  content: (() => {
    const PICTOGRAM_FIRST = <WifiPictogram className={styles.svg} />;
    const PICTOGRAM_SECOND = <RestaurantPictogram className={styles.svg} />;
    const PICTOGRAM_THIRD = <HotBeveragePictogram className={styles.svg} />;

    return (
      <div className={styles.root}>
        <CustomHeading>
          <Node uri="home/info/onTrip/innerTitle.md">{md`
Under resan
          `}</Node>
        </CustomHeading>
        <CustomText>
          <Node uri="home/info/onTrip/content.md">{md`
            Boka sommarens resor fram till 1 september, nu även till Varberg, Falkenberg och Halmstad.
          `}</Node>
        </CustomText>
        <div className={styles.pictograms}>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(OT_LINK_FIRST, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>{PICTOGRAM_FIRST}</a>
                  );
                }
                return PICTOGRAM_FIRST;
              },
            })}
            <CustomText>
              <Node uri="home/info/onTrip/first.md">
                Hos oss surfar du kostnadsfritt och obegränsat hela vägen från
                Stockholm till Göteborg. Koppla av med din favoritserie eller
                sätt dig in i excelarket utan att bli avbruten.
              </Node>
            </CustomText>
          </div>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(OT_LINK_SECOND, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>
                      {PICTOGRAM_SECOND}
                    </a>
                  );
                }
                return PICTOGRAM_SECOND;
              },
            })}
            <CustomText>
              <Node uri="home/info/onTrip/second.md">
                Du som har en plats i 1 KLASS PLUS kan lugnt luta dig tillbaka i
                stolen och ta det lugnt. Vi serverar dig nämligen din måltid vid
                platsen. Skönt, eller hur?
              </Node>
            </CustomText>
          </div>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(OT_LINK_THIRD, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>{PICTOGRAM_THIRD}</a>
                  );
                }
                return PICTOGRAM_THIRD;
              },
            })}
            <CustomText>
              <Node uri="home/info/onTrip/third.md">
                Rycker det i fikanerven? I så fall kan du ta en titt i vår
                cafémeny! Den hittar du i stolsfickan. Vi har massor av gott i
                cafét, och har du tur kan du även hitta en pratglad cafévärd i
                sortimentet. Välkommen!
              </Node>
            </CustomText>
          </div>
        </div>
      </div>
    );
  })(),
};

// Needs to be global scope for some reason, guesing djedi-react babel plugin doesn't work in functions....
const AT_LINK_FIRST = <Node uri="home/info/afterTrip/first-link" />;
const AT_LINK_SECOND = <Node uri="home/info/afterTrip/second-link" />;
const AT_LINK_THIRD = <Node uri="home/info/afterTrip/third-link" />;

const TAB_3 = {
  title: <Node uri="home/info/afterTrip/title">Framme</Node>,
  content: (() => {
    const PICTOGRAM_FIRST = <SustainabilityPictogram className={styles.svg} />;
    const PICTOGRAM_SECOND = <SocialPictogram className={styles.svg} />;
    const PICTOGRAM_THIRD = <MyVrPictogram className={styles.svg} />;

    return (
      <div className={styles.root}>
        <CustomHeading>
          <Node uri="home/info/afterTrip/innerTitle.md">{md`
Framme
          `}</Node>
        </CustomHeading>
        <CustomText>
          <Node uri="home/info/afterTrip/content.md">{md`
            Boka sommarens resor fram till 1 september, nu även till Varberg, Falkenberg och Halmstad.
          `}</Node>
        </CustomText>
        <div className={styles.pictograms}>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(AT_LINK_FIRST, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>{PICTOGRAM_FIRST}</a>
                  );
                }
                return PICTOGRAM_FIRST;
              },
            })}
            <CustomText>
              <Node uri="home/info/afterTrip/first.md">
                Genom att ta tåget gör du både dig själv och din planet en god
                gärning. Med MTRX tåg sparar du över 74 000 gånger mer koldioxid
                jämfört med flyget.
              </Node>
            </CustomText>
          </div>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(AT_LINK_SECOND, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>
                      {PICTOGRAM_SECOND}
                    </a>
                  );
                }
                return PICTOGRAM_SECOND;
              },
            })}
            <CustomText>
              <Node uri="home/info/afterTrip/second.md">
                Får du inte nog av oss? Följ oss gärna på Instagram @wearemtrx
                där vi i MTRX-familjen hänger varje dag. Välkommen in i värmen!
              </Node>
            </CustomText>
          </div>
          <div className={styles.pictoWrapper}>
            {React.cloneElement(AT_LINK_THIRD, {
              render: function render(state) {
                if (
                  state.type === "success" &&
                  state.content &&
                  state.content.props.children
                ) {
                  return (
                    <a href={state.content.props.children}>{PICTOGRAM_THIRD}</a>
                  );
                }
                return PICTOGRAM_THIRD;
              },
            })}
            <CustomText>
              <Node uri="home/info/afterTrip/third.md">
                Woho! Du har precis tjänat in poäng. Kika in på ditt ClubX-konto
                för att se hur du ligger till just nu. Kanske har du
                tillräckligt för att boka din nästa resa?
              </Node>
            </CustomText>
          </div>
        </div>
      </div>
    );
  })(),
};

const TabbedInfo = () => (
  <Tabs disallowAccordion tabs={[TAB_1, TAB_2, TAB_3]} />
);

export default TabbedInfo;
