import { ForceNodes, md, Node } from "djedi-react";
import cookies from "js-cookie";
import Cookies from "js-cookie";
import getConfig from "next/config";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React from "react";
import { ROUTES } from "routes";

import Announcements from "#components/Announcements";
import { useAppContext } from "#components/AppContext";
import ArrowLink from "#components/ArrowLink";
import BigCard from "#components/BigCard";
import Button from "#components/Button";
import Container from "#components/Container";
import Heading from "#components/Heading";
import Hero from "#components/Hero";
import Layout from "#components/Layout";
import { NAV_ID } from "#components/Nav";
import PromoCard from "#components/PromoCard";
import Push from "#components/Push";
import SearchForm from "#components/SearchForm";
import Section from "#components/Section";
import Text from "#components/Text";
import BookingContainer from "#containers/Booking";
import { BOOKING_STATE_COOKIE_NAME } from "#containers/Booking/BookingContext/consts";
import { Steps } from "#containers/Booking/consts";
import {
  getFromTo,
  getStops,
  makeRoutesMap,
} from "#containers/Booking/helpers";
import TabbedInfo from "#pages-helpers/index-partials/TabbedInfo";
import { deleteCookie } from "#utils/";
import { VOUCHER_COOKIE_NAME } from "#utils/booking";

import { responsePropType } from "../api";
import styles from "./index.module.css";

const { publicRuntimeConfig } = getConfig();

const { DISABLE_BOOKING } = publicRuntimeConfig;

const HERO = {
  REGULAR: {
    heading: (
      <Node uri="home/heading.md">{md`
Vi reser<br/>med dig
      `}</Node>
    ),
    subHeading: (
      <Node uri="home/sub-heading">
        Boka sommarens resor fram till 1 september, nu även till Varberg,
        Falkenberg och Halmstad.
      </Node>
    ),
  },
  IMAGE: <Node uri="home/hero-background.img" />,
  BOOKING_DISABLED: {
    heading: <Node uri="home/booking-disabled/heading">Vi reser med dig</Node>,
    subHeading: <Node uri="home/booking-disabled/sub-heading" />,
  },
};

export const WARNING_MODAL = {
  TITLE: <Node uri="home/warning-modal/title">Vill du gå vidare?</Node>,
  ON_CONFIRM: (
    <Node uri="home/warning-modal/on-confirm">Fortsätt utan kod</Node>
  ),
  CLOSE: <Node uri="home/warning-modal/close">Ändra resval</Node>,
  CONTENT: (
    <Node uri="home/warning-modal/content">
      Stationerna du har valt ingår inte i din biljett. Om du går vidare så
      kommer dina val att ändras till de som ingår i din biljett.
    </Node>
  ),
};

const BOOKING_DISABLED_INFO = (
  <Node uri="home/booking-disabled/info.md">
    {md`
      ## För närvarande går det inte boka online

      Ta det lugnt – vi kör ändå alla tåg. Välkommen ombord så löser vi biljett
      på plats.

      Kontakta [kundservice](/sv/kundservice/tickets/new) om du undrar över något.
    `}
  </Node>
);

const BIG_CARD = {
  IMAGE: <Node uri="home/big-card/image.img" />,
  LINKTEXT: <Node uri="home/big-card/linkText">Designarbetet</Node>,
  LINKHREF: <Node uri="home/big-card/linkHref">https://mtrx.travel</Node>,
  HEADING: <Node uri="home/big-card/heading">Möt Peter - lokförare på VR</Node>,
  CONTENT: (
    <Node uri="home/big-card/node.md">
      {md`
Välkommen till nya MTR Express! Nytt utseende, men samma service. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      `}
    </Node>
  ),
};

const PUSHES = [
  {
    HEADING: (
      <Node uri="home/push-first/headline">Kundservice och support</Node>
    ),
    CONTENT: (
      <Node uri="home/push-first/content.md">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus
        ipsum eget nibh accumsan.
      </Node>
    ),
    CTA_TEXT: <Node uri="home/push-first/cta-text"></Node>,
    CTA_URL: <Node uri="home/push-first/cta-url"></Node>,
  },
  {
    IMAGE: <Node uri="home/push-second/image.img" />,
    HEADING: <Node uri="home/push-second/headline"> Vi gillar friidrott </Node>,
    CONTENT: (
      <Node uri="home/push-second/content.md">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus
        ipsum eget nibh accumsan.
      </Node>
    ),
    CTA_TEXT: <Node uri="home/push-second/cta-text"></Node>,
    CTA_URL: <Node uri="home/push-second/cta-url"></Node>,
  },
];

Home.propTypes = {
  routesResponse: responsePropType.isRequired,
  stopsFrom: PropTypes.array.isRequired,
  stopsTo: PropTypes.array.isRequired,
  fromTo: PropTypes.object.isRequired,
  voucherResponse: PropTypes.object,
};

Home.defaultProps = {
  voucherResponse: undefined,
};

Home.getInitialProps = async ({ api, req, res }) => {
  const allCookies = req != null ? req.cookies : cookies.get();
  const voucherCode = allCookies[VOUCHER_COOKIE_NAME];

  const [stopsResponse, routesResponse, voucherResponse] = await Promise.all([
    api.getStops(),
    api.getRoutes(),
    voucherCode != null
      ? api.getVoucher({ code: voucherCode }).catch((error) => {
          if (
            error.response != null &&
            // 412 = Max use reached
            (error.response.status === 404 || error.response.status === 412)
          ) {
            deleteCookie(res, VOUCHER_COOKIE_NAME);
            return undefined;
          }
          throw error;
        })
      : undefined,
  ]);

  const stops = stopsResponse?.data;
  const routes = routesResponse?.data;
  const voucher = voucherResponse?.data;

  const routesMap = makeRoutesMap(routes);

  const stopsFrom =
    voucher == null ? stops : getStops(routesMap, voucher.from_stops, stops);
  const stopsTo =
    voucher == null ? stops : getStops(routesMap, voucher.to_stops, stops);

  const { from, to } = getFromTo({ voucher, stopsFrom, stopsTo });

  const fromTo = { from: from.id, to: to.id };
  const r = {
    routesResponse,
    voucher,
    stopsFrom,
    stopsTo,
    routesMap,
    stops,
    voucherResponse,
    fromTo,
  };

  return r;
};

export default function Home({
  routesResponse,
  voucherResponse,
  stopsFrom,
  stopsTo,
  fromTo,
}) {
  const router = useRouter();

  const { api } = useAppContext();
  const [bookingOpen, setbookingOpen] = React.useState(false);
  const [booking, setBooking] = React.useState(undefined);

  React.useEffect(() => {
    const r = Cookies.get(BOOKING_STATE_COOKIE_NAME);
    if (r) {
      try {
        setBooking(JSON.parse(r));
      } catch {
        // do nothing. The booking state is reset.
      }
    }
  }, []);

  React.useEffect(() => {
    api.reviveCache(routesResponse);
    if (voucherResponse) {
      api.reviveCache(voucherResponse);
    }
  }, [routesResponse, api, voucherResponse]);

  const hero = DISABLE_BOOKING ? HERO.BOOKING_DISABLED : HERO.REGULAR;

  const onSearch = (ctx) => {
    const { data: query, setLoading } = ctx;
    const { pathname } = ROUTES.booking;

    setLoading(true);
    router.push({ pathname, query: { ...query, show: "departures" } });
  };

  return (
    <>
      <Announcements />
      <Layout
        title={
          <Node uri="home/title">
            Boka tågbiljetter – Res Stockholm–Göteborg
          </Node>
        }
        background="grey"
        description={
          <Node uri="home/description">{`Boka din resa mellan Göteborg och Stockholm med MTRX – Det moderna snabbtåget. Tågbolaget med Sveriges nöjdaste kunder enligt Svenskt Kvalitetsindex. Punktligast. Lägst pris. Miljövänligt. Modernt. Gratis WiFi. Nöjdast kunder. 24h öppet köp.`}</Node>
        }
      >
        {/** TODO: Srcsets for hero */}
        <Hero imageNode={HERO.IMAGE} height="dynamic">
          <Section padding="none" position="top">
            <div>
              <div className={styles.hero} data-cy="home-hero">
                <Heading tag="h1" variant="xxxl" color="white">
                  {hero.heading}
                </Heading>
                <Heading
                  className={styles.heroSubHeading}
                  tag="h2"
                  variant="title5"
                  color="white"
                >
                  {hero.subHeading}
                </Heading>
              </div>
              <div className={styles.searchTrip}>
                {DISABLE_BOOKING ? (
                  <BookingDisabledInfo />
                ) : (
                  <SearchForm
                    fromTo={
                      booking
                        ? { from: booking.from.id, to: booking.to.id }
                        : fromTo
                    }
                    stopsFrom={stopsFrom}
                    stopsTo={stopsTo}
                    onSearch={async (_fromTo) => {
                      setbookingOpen(true);
                      Cookies.set(
                        BOOKING_STATE_COOKIE_NAME,
                        JSON.stringify(_fromTo),
                        {
                          expires: 1,
                          path: "",
                        }
                      );
                      setBooking(_fromTo);
                    }}
                  />
                )}
              </div>
            </div>
          </Section>
        </Hero>
        {bookingOpen && booking ? (
          (() => {
            const { from, to } = booking;

            const fromTo = { from: from.id, to: to.id };
            return (
              <BookingContainer
                onSubmitOverrides={{
                  [Steps.SEARCH]: onSearch,
                }}
                steps={[1]}
                key={Object.values(fromTo).join("|")}
                initialData={fromTo}
                layout={{
                  hideSteps: true,
                  hideSearchForm: true,
                  navSelector: `#${NAV_ID}`,
                }}
              />
            );
          })()
        ) : (
          <>
            <Section variant="white" padding="regular">
              <Container className={styles.pushContainer}>
                {PUSHES.map((p, i) => (
                  <Push key={i} image={p.IMAGE} heading={p.HEADING}>
                    {p.CONTENT}
                    {React.cloneElement(p.CTA_URL, {
                      render: function render(state) {
                        if (state.type === "success") {
                          const url = state?.content?.props?.children;
                          return url ? (
                            <ArrowLink href={state?.content?.props?.children}>
                              {p.CTA_TEXT}
                            </ArrowLink>
                          ) : null;
                        }

                        return null;
                      },
                    })}
                  </Push>
                ))}
              </Container>
            </Section>
            <Section variant="white" padding="regular">
              <Container>
                <div className={styles.grid}>
                  {[
                    <PromoCard
                      key="1"
                      image={<Node uri="home/PromoCard1/image.img" />}
                      buttonText={
                        <Node uri="home/PromoCard1/buttonText">Läs mer</Node>
                      }
                      href={
                        <Node uri="home/PromoCard1/linkTarget">
                          http://mtrx.se
                        </Node>
                      }
                      subtitle={
                        <Node uri="home/PromoCard1/subtitle">Subtitle</Node>
                      }
                      title={
                        <Node uri="home/PromoCard1/title">
                          Missa inte Miriam. Res Stockholm-Göteborg från 195kr.
                        </Node>
                      }
                      content={
                        <Node uri="home/PromoCard1/text.md">{md`
                          Missa inte Miriam. Res Stockholm-Göteborg från 195kr.
                        `}</Node>
                      }
                    />,
                    <PromoCard
                      key="2"
                      image={<Node uri="home/PromoCard2/image.img" />}
                      href={
                        <Node uri="home/PromoCard2/linkTarget">
                          http://mtrx.se
                        </Node>
                      }
                      buttonText={
                        <Node uri="home/PromoCard2/buttonText">Läs mer</Node>
                      }
                      subtitle={
                        <Node uri="home/PromoCard2/subtitle">Subtitle</Node>
                      }
                      title={
                        <Node uri="home/PromoCard2/title">
                          Dela din resa på #mtrxstories och få 300 club
                          Xpress-poäng.
                        </Node>
                      }
                      content={
                        <Node uri="home/PromoCard2/text.md">{md`
                          Dela din resa på #mtrxstories och få 300 club Xpress-poäng.
                        `}</Node>
                      }
                    />,
                    <PromoCard
                      key="3"
                      image={<Node uri="home/PromoCard3/image.img" />}
                      buttonText={
                        <Node uri="home/PromoCard3/buttonText">Läs mer</Node>
                      }
                      href={
                        <Node uri="home/PromoCard3/linkTarget">
                          http://mtrx.se
                        </Node>
                      }
                      subtitle={
                        <Node uri="home/PromoCard3/subtile">subtitle</Node>
                      }
                      title={
                        <Node uri="home/PromoCard3/title">
                          Vi gillar studenter, kolla in våra rabatter!
                        </Node>
                      }
                      content={
                        <Node uri="home/PromoCard3/text.md">{md`
                          Vi gillar studenter, kolla in våra rabatter!
                        `}</Node>
                      }
                    />,
                  ].map((card) => (
                    <div key={card.key}>{card}</div>
                  ))}
                </div>
              </Container>
            </Section>
            <Section variant="grey" padding="regular">
              <Container>
                <TabbedInfo />
              </Container>
            </Section>
            <BigCard image={BIG_CARD.IMAGE} heading={BIG_CARD.HEADING}>
              <Text>{BIG_CARD.CONTENT}</Text>
              {React.cloneElement(BIG_CARD.LINKHREF, {
                render(state) {
                  if (state.type === "success") {
                    return (
                      <Button
                        color="secondary"
                        passHref
                        href={state.content.props.children}
                      >
                        {BIG_CARD.LINKTEXT}
                      </Button>
                    );
                  }

                  return null;
                },
              })}
            </BigCard>
          </>
        )}

        <ForceNodes>
          {BIG_CARD}
          {WARNING_MODAL}
          {HERO}
          {BOOKING_DISABLED_INFO}
          {PUSHES[0]}
          {PUSHES[1]}
        </ForceNodes>
      </Layout>
    </>
  );
}

function BookingDisabledInfo() {
  return (
    <div className={styles.BookingDisabledInfo}>
      <Text>{BOOKING_DISABLED_INFO}</Text>
    </div>
  );
}
