import Router from "next/router";
import PropTypes from "prop-types";
import React from "react";

import AppContext from "#components/AppContext";
import SearchTrip, { getDefaultFromTo } from "#components/SearchTrip";
import { filterIsSearchable } from "#containers/Booking/helpers";
import { noop } from "#utils/";
import { dataLayerPush } from "#utils/tracking";

import { ROUTES } from "../../routes";

const getPlainTextStation = (passedId, set) => {
  return set.find(({ id }) => id === passedId).name;
};

const SearchForm = ({
  disableSwitch,
  stopsTo,
  stopsFrom,
  fromTo,
  onSearch,
  onChange,
  leftDisabled,
  rightDisabled,
}) => {
  const { language } = React.useContext(AppContext);

  const [{ from, to }, setStations] = React.useState(
    fromTo || getDefaultFromTo(stopsTo, stopsFrom)
  );

  React.useEffect(() => {
    fromTo && setStations(fromTo);
  }, [fromTo]);

  return (
    <SearchTrip
      from={from}
      disableSwitch={disableSwitch}
      leftDisabled={leftDisabled}
      rightDisabled={rightDisabled}
      to={to}
      stopsFrom={filterIsSearchable(stopsFrom)}
      stopsTo={filterIsSearchable(stopsTo)}
      onChange={(values) => {
        onChange(values);
        setStations({ from: values.from.id, to: values.to.id });
      }}
      onSearch={({ from: newFrom, to: newTo }) => {
        const plainFrom = getPlainTextStation(from, stopsFrom);
        const plainTo = getPlainTextStation(to, stopsTo);
        dataLayerPush({ event: "search-trip", from: plainFrom, to: plainTo });
        onSearch({ from: newFrom, to: newTo, language });
      }}
    />
  );
};

SearchForm.propTypes = {
  stopsTo: PropTypes.arrayOf(PropTypes.object).isRequired,
  stopsFrom: PropTypes.arrayOf(PropTypes.object).isRequired,
  fromTo: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
};

SearchForm.defaultProps = {
  fromTo: undefined,
  onSearch: ({ from, to }) => {
    const { pathname } = ROUTES.booking;
    Router.push({ pathname, query: { from: from.id, to: to.id } });
  },
  onChange: noop,
};

export default SearchForm;
