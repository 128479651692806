import { Node } from "djedi-react";
import React from "react";

import StepsComponent from "#components/Steps";
import { useBooking } from "#containers/Booking/BookingContext";
import { Steps } from "#containers/Booking/consts";

const STEPS = [
  {
    step: Steps.SEARCH,
    text: (
      <Node key="1" uri="BookingSteps/1">
        Sök resa
      </Node>
    ),
  },
  {
    step: Steps.DEPARTURES,
    text: (
      <Node key="2" uri="BookingSteps/2">
        Välj avgång
      </Node>
    ),
  },
  {
    step: Steps.PASSENGERS,
    text: (
      <Node key="3" uri="BookingSteps/3">
        Bokningsuppgifter
      </Node>
    ),
  },
  {
    step: Steps.UPGRADE,
    text: (
      <Node key="4" uri="BookingSteps/7">
        Uppgradera
      </Node>
    ),
  },
  {
    step: Steps.SEATING,
    text: (
      <Node key="5" uri="BookingSteps/4">
        Dina platser
      </Node>
    ),
  },
  {
    step: Steps.ADDONS,
    text: (
      <Node key="6" uri="BookingSteps/5">
        Tillval
      </Node>
    ),
  },
  {
    step: Steps.PAYMENT,
    text: (
      <Node key="7" uri="BookingSteps/6">
        Betala
      </Node>
    ),
  },
];

export default function BookingSteps() {
  const { show, setShow, disabled, steps, back, layout } = useBooking();
  const { navSelector } = layout;

  return (
    <StepsComponent
      back={back}
      disabled={disabled}
      navSelector={navSelector}
      setStep={setShow}
      step={show}
      steps={steps}
      stepsData={STEPS}
      hideBack={Steps.SEARCH >= show}
    />
  );
}
