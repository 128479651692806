import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

/**
 * imageNode expects a Djedi node.
 * imageUrl is for plain urls "/static/xx.jpg"
 */
import NodeAsBackground from "#components/NodeAsBackground";

import Container from "../Container";
import styles from "./Hero.module.css";

const Hero = ({ imageNode, imageSrc, height, children }) => {
  const noImage = Boolean(imageNode !== undefined || imageSrc !== undefined);
  return (
    <div
      className={classnames(styles.root, {
        [styles.fullHeight]: height === "full",
        [styles.dynamicHeight]: height === "dynamic",
        [styles.setHeightLarge]: height === "large",
        [styles.setHeightMedium]: height === "medium",
        [styles.setHeightSmall]: height === "small",
        [styles.adaptHeight]: height === "adapt",
        [styles.hasBackground]: noImage,
      })}
    >
      {imageNode &&
        React.cloneElement(imageNode, {
          render: function render(state) {
            return (
              <NodeAsBackground
                state={state}
                className={styles.backgroundImage}
              />
            );
          },
        })}
      {imageSrc && (
        <div
          style={{
            backgroundImage: `url(${imageSrc})`,
          }}
          className={styles.backgroundImage}
        />
      )}

      <Container fullHeight className={styles.inner}>
        {children}
      </Container>
    </div>
  );
};

Hero.propTypes = {
  imageNode: PropTypes.node,
  height: PropTypes.oneOf([
    "full",
    "dynamic",
    "small",
    "medium",
    "large",
    "adapt",
  ]),
  imageSrc: PropTypes.string,
  children: PropTypes.node,
};
Hero.defaultProps = {
  imageNode: undefined,
  children: undefined,
  imageSrc: undefined,
  height: "medium",
};

export default Hero;
