import { ForceNodes } from "djedi-react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React from "react";

import { BookingContextProvider, useBooking } from "./BookingContext";
import {
  initialDataPropType,
  layoutDefaultProp,
  layoutPropType,
  stepsDefaultProp,
  stepsPropType,
} from "./BookingContext/consts";
import { BOOKING_CONTAINER_ID, STATIONS, Steps } from "./consts";
import * as BOOKING_NODES from "./nodes";
import BookingSteps from "./partials/BookingSteps";
import Loading from "./partials/Loading";
import { TrackingContextProvider } from "./TrackingContext";

const STEPS = {
  [Steps.SEARCH]: dynamic(() => import("./steps/Search"), {
    loading: Loading,
  }),
  [Steps.DEPARTURES]: dynamic(() => import("./steps/Departures"), {
    loading: Loading,
  }),
  [Steps.PASSENGERS]: dynamic(() => import("./steps/Passengers"), {
    loading: Loading,
  }),
  [Steps.UPGRADE]: dynamic(() => import("./steps/Upgrade"), {
    loading: Loading,
  }),
  [Steps.SEATING]: dynamic(() => import("./steps/Seating"), {
    loading: Loading,
  }),
  [Steps.ADDONS]: dynamic(() => import("./steps/Addons"), {
    loading: Loading,
  }),
  [Steps.PAYMENT]: dynamic(() => import("./steps/Payment"), {
    loading: Loading,
  }),
};

BookingContainer.propTypes = {
  initialData: initialDataPropType,
  steps: stepsPropType,
  children: PropTypes.node,
  layout: layoutPropType,
  onSubmitOverrides: PropTypes.object,
  tracking: PropTypes.object,
  isRebook: PropTypes.bool,
  oldJourney: PropTypes.object,
};

BookingContainer.defaultProps = {
  initialData: {
    from: STATIONS["Stockholm C"],
    to: STATIONS["Göteborg C"],
  },
  steps: stepsDefaultProp,
  children: undefined,
  layout: layoutDefaultProp,
  onSubmitOverrides: {},
  tracking: {},
  isRebook: false,
  oldJourney: undefined,
};

export default function BookingContainer({
  initialData,
  steps,
  children,
  layout,
  onSubmitOverrides,
  tracking,
  isRebook,
  isUpgrade,
  oldJourney,
  ...rest
}) {
  return (
    <>
      <ForceNodes>{BOOKING_NODES}</ForceNodes>
      <BookingContextProvider
        layout={layout}
        steps={steps}
        initialData={initialData}
        onSubmitOverrides={onSubmitOverrides}
        isRebook={isRebook}
        isUpgrade={isUpgrade}
        oldJourney={oldJourney}
        {...rest}
      >
        <TrackingContextProvider tracking={tracking}>
          <Content />
          {children}
        </TrackingContextProvider>
      </BookingContextProvider>
    </>
  );
}

function Content() {
  const ctx = useBooking();
  const { show: showState, getNextStep, layout, onSubmitOverrides } = ctx;
  const show = showState;

  const { hideSteps } = layout;

  const CurrentStep = STEPS[show];

  React.useEffect(() => {
    const nextNum = getNextStep();

    if (nextNum) {
      const NextStep = STEPS[nextNum];
      NextStep.render.preload();
    }
  }, [show, getNextStep]);

  return (
    <div id={BOOKING_CONTAINER_ID}>
      {!hideSteps && <BookingSteps />}
      {CurrentStep ? (
        <CurrentStep onSubmitOverride={onSubmitOverrides[show]} />
      ) : (
        "..."
      )}
    </div>
  );
}
