import PropTypes from "prop-types";
import React from "react";

const SRC_REGEXP = new RegExp(/src=(".+?")/);

const NodeAsBackground = ({ state, ...props }) => {
  if (state.type === "success") {
    const asHtml = state?.content?.props?.dangerouslySetInnerHTML?.__html;

    if (asHtml !== undefined) {
      const s = SRC_REGEXP.exec(
        state.content.props?.dangerouslySetInnerHTML?.__html
      );

      const src = s.length > 1 ? s[1] : "";

      return (
        <div
          {...props}
          style={{
            backgroundImage: `url(${src})`,
          }}
        />
      );
    }
  }
  return null;
};

NodeAsBackground.propTypes = {
  state: PropTypes.object.isRequired,
};

export default NodeAsBackground;
