import React from "react";

import Spinner from "#components/Spinner";

import styles from "./Loading.module.css";

export default function Loading() {
  return (
    <div className={styles.root}>
      <Spinner size={100} />
    </div>
  );
}
