import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Container from "../Container";
import Heading from "../Heading";
import Section from "../Section";
import styles from "./BigCard.module.css";

const BigCard = ({
  heading,
  children,
  headingProps,
  containerProps,
  sectionProps,
  reverse,
  image,
}) => {
  return (
    <Section variant="grey" {...sectionProps}>
      <Container {...containerProps}>
        <div className={classnames(styles.root, { [styles.reverse]: reverse })}>
          <div className={styles.imageContainer}>{image}</div>
          <div className={styles.content}>
            {heading && (
              <Heading variant="title2" color="textPrimary" {...headingProps}>
                {heading}
              </Heading>
            )}
            {children}
          </div>
        </div>
      </Container>
    </Section>
  );
};

BigCard.propTypes = {
  heading: PropTypes.node,
  children: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
  headingProps: PropTypes.object,
  containerProps: PropTypes.object,
  sectionProps: PropTypes.object,
  reverse: PropTypes.bool,
};

BigCard.defaultProps = {
  reverse: false,
  heading: null,
  headingProps: {},
  sectionProps: {},
  containerProps: {},
};

export default BigCard;
