import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Heading from "#components/Heading";
import NodeAsBackground from "#components/NodeAsBackground";
import Text from "#components/Text";

import styles from "./Push.module.css";

const Push = ({ heading, children, headingProps, textProps, image }) => {
  const content = (
    <div className={styles.content}>
      {heading && (
        <Heading variant="title1" color={"white"} {...headingProps}>
          {heading}
        </Heading>
      )}
      <Text coloredLinks={false} color={"white"} {...textProps}>
        {children}
      </Text>
    </div>
  );

  if (!image) {
    return <div className={styles.root}>{content}</div>;
  }
  return React.cloneElement(image, {
    render: function render(state) {
      return (
        <NodeAsBackground
          state={state}
          className={classnames(styles.root, {
            [styles.withImage]: image,
          })}
        >
          {content}
        </NodeAsBackground>
      );
    },
  });
};

Push.propTypes = {
  heading: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  image: PropTypes.node,
  headingProps: PropTypes.object,
  containerProps: PropTypes.object,
  textProps: PropTypes.object,
  reverse: PropTypes.bool,
};

Push.defaultProps = {
  reverse: false,
  headingProps: {},
  textProps: {},
  image: null,
  containerProps: {},
};

export default Push;
